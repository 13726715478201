import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import {
    buildLoadActivityGroupsUrl,
    buildConfigurationActivityGroupUrl,
} from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addEditConfigurationActivityGroups = (
    payload: Record<string, string>,
    id?: string,
): Observable<any> => {
    const isAdd = !id;
    const url = isAdd ? buildLoadActivityGroupsUrl() : buildConfigurationActivityGroupUrl(id ?? '');
    return sendRequestWithXSRFToken(url, payload, isAdd ? 'POST' : 'PUT').pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const activityGroupResponse = convertToCamel<any>(response);
            return observableOf(activityGroupResponse);
        }),
    );
};
